import { configureStore } from "@reduxjs/toolkit";
import purchaseOrderSlice from "./reducers/PurchaseOrders/purchaseOrderSlice";
import buyerSlice from "./reducers/Buyer/buyerSlice";

export const store = configureStore({
  reducer: {
    purchaseOrders: purchaseOrderSlice,
    buyer: buyerSlice,
  },
});
