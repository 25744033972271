import { useSelector } from "react-redux";
import { getPurchaseOrders } from "../../app/reducers/PurchaseOrders/purchaseOrderSlice";
import { array, object, string } from "yup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikMultiDateSelect from "../../components/formik/FormikMultiDateSelect";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { OUTSTANDING_AMOUNT } from "../../utils/dropdownOptions";
import { ArrowLeft } from "react-feather";
import { Fade } from "react-reveal";
import { useEffect, useState } from "react";
const validationSchema = object({
  chalanDates: array().min(1),
  chalanName: array().required("Required"),
  outstandingAmount: string().required("Required"),
});

const STEP = 4;
const action = "Select chalan Dates and chalan Name";

const Step = ({
  formik,
  currentStep,
  setCurrentStep,
  addVendorChallanNameModalOpen,
  setAddVendorChallanNameModalOpen,
}) => {
  const { vendor } = useSelector(getPurchaseOrders);
  const [challanName, setChallanName] = useState([]);

  useEffect(() => {
    if (vendor?.cf_chalan_name) {
      setChallanName(
        vendor?.cf_chalan_name
          ?.split(",")
          ?.map((ele) => ({ label: ele.trim(), value: ele.trim() }))
      );
    }
  }, [vendor]);
  return (
    <>
      {currentStep + 1 === STEP && (
        <>
          <div className="absolute">
            <ArrowLeft
              type="button"
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
              className="mr-2"
            />
          </div>
          <Fade distance="30px" bottom duration={1000}>
            <h1 className="font-bold ">
              कृपया अपनी तारीख और चालान का नाम चुनें!
            </h1>
          </Fade>

          <FormikMultiDateSelect
            name="chalanDates"
            label="chalan Dates"
            formik={formik}
          />
          <FormikMultiSelect
            name="chalanName"
            label="chalan Name"
            formik={formik}
            options={challanName}
          />

          <p
            className="underline text-indigo-600 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setAddVendorChallanNameModalOpen(true);
            }}
          >
            Click Here to Add Challan Name
          </p>
          <FormikSelectGroup
            name="outstandingAmount"
            label="Outstanding Amount"
            formik={formik}
            options={OUTSTANDING_AMOUNT}
          />
          <div>
            <PrimaryButton
              type="button"
              className="mr-2"
              onClick={async () => {
                const validation = await formik.validateForm();
                if (_.isEmpty(validation)) {
                  formik.submitForm();
                } else {
                  alert("Please fill all the required fields");
                }
              }}
            >
              Submit
            </PrimaryButton>
          </div>
        </>
      )}
    </>
  );
};

export default {
  Component: Step,
  validationSchema: validationSchema,
  action,
};
