const STEP = 6;

const Step = ({ setCurrentStep, currentStep }) => {
  return (
    <>
      {currentStep + 1 === STEP && (
        <div
          // style={{
          //   backgroundImage: "url('/congrat.gif')",
          //   backgroundSize: "cover",
          // }}
          className="flex flex-col items-center relative"
        >
          <img
            className="h-[25rem] w-[20rem] "
            src="/thank.gif"
            alt="complaint_success"
          />
          <div
            className="absolute left  w-24 h-12 top-[5%] left-[3%]  cursor-pointer"
            onClick={() => {
              setCurrentStep(2);
            }}
          ></div>
          {/* <h2 className="text-center text-2xl">Congratulations</h2> */}
          {/* <img src="/congrat.gif" alt="congratulations gif"/> */}
        </div>
      )}
    </>
  );
};

export default {
  Component: Step,
};
