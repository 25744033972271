import resolveConfig from "tailwindcss/resolveConfig";
import geo from "../assets/countries+states+cities.json";
import { saveAs } from "file-saver";
import { authAxiosInstance } from "./axiosConfig";
import moment from "moment";

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig("./src/css/tailwind.config.js");
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(value);

export const formatThousands = (value) =>
  Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(value);

export const generateOptions = ({ array, valueField, labelField }) => {
  return array?.map
    ? array.map((ele) => ({
        value: ele[valueField],
        label: ele[labelField],
      }))
    : [];
};
export const generateOptionsForPurchaseOrder = ({
  array,
  valueField,
  labelField,
  labelField2,
}) => {
  return array?.map
    ? array.map((ele) => ({
        value: ele[valueField],
        label: `[${ele[labelField]}] ${ele[labelField2]}  [${moment(
          ele.date
        ).format("DD/MM/YYYY")}]`,
      }))
    : [];
};
export const bytesToMegaBytes = (bytes) => bytes / 1024 ** 2;
export const checkURL = (url) => {
  return url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null;
};
export const findCountryInGeo = (country) => {
  return geo.find((ele) => ele.name === country);
};
export const findStatesInCountry = (country) => {
  if (_.isArray(country)) {
    const states = geo.reduce((acc, ele) => {
      if (country.includes(ele.name)) {
        acc = acc.concat(ele.states);
      }
      return acc;
    }, []);
    return states;
  } else {
    const countryFound = geo.find((ele) => ele.name === country);
    const states = countryFound?.states ? countryFound.states : [];
    return states;
  }
};
export const findCitiesInState = (country, state) => {
  const countries = [].concat(country);
  const states = [].concat(state);
  const countriesFound = geo.reduce((acc, ele) => {
    if (countries.includes(ele.name)) {
      acc = acc.concat(ele);
    }
    return acc;
  }, []);

  const statesFound = countriesFound.reduce((acc, ele) => {
    const temp = ele.states.filter((stateData) =>
      states.includes(stateData.name)
    );
    acc = acc.concat(temp);
    return acc;
  }, []);
  const cities = statesFound.reduce((acc, ele) => {
    acc = acc.concat(ele.cities);
    return acc;
  }, []);

  return cities;
};

export const exportCsv = async (data) => {
  authAxiosInstance
    .post("exportCsv", { data: data }, { responseType: "blob" })
    .then((response) => {
      saveAs(response.data);
    });
};

export const formatPurchaseOrderLabel = ({ fullElement }) =>
  `${fullElement.purchaseorder_number} ${fullElement.cf_buyer_name}`;
