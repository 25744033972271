import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
const axios = require("axios");

const initialState = {
  purchaseOrdersRaw: {},
  vendor: {},
  purchaseOrders: [],
  loading: false,
  chalanNameLoading: false,
};

export const fetchPurchaseOrders = createAsyncThunk(
  "purchaseOrders/fetchPurchaseOrders",
  async (data, { rejectWithValue }) => {
    try {
      const { phone } = data;
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}zoho/purchase-orders/${phone}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }
      toast.error("Error fetching Purchase Orders");
      return rejectWithValue(JSON.stringify(error));
    }
  }
);

export const updatePurchaseOrder = createAsyncThunk(
  "purchaseOrders/updatePurchaseOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}zoho/purchase-orders/`,
        data
      );
      console.log(response.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }
      toast.error("Error updating Purchase Orders");
      return rejectWithValue(JSON.stringify(error));
    }
  }
);

export const updateChalanName = createAsyncThunk(
  "purchaseOrders/updateChalanName",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}zoho/purchase-orders/chalan-name`,
        data
      );
      console.log(response.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }
      toast.error("Error fetching Purchase Orders");
      return rejectWithValue(JSON.stringify(error));
    }
  }
);

const purchaseOrdersReducer = createSlice({
  initialState: initialState,
  name: "purchaseOrders",
  extraReducers: {
    [fetchPurchaseOrders.pending]: (state) => {
      state.loading = true;
    },
    [fetchPurchaseOrders.fulfilled]: (state, action) => {
      state.purchaseOrdersRaw = action.payload;
      state.purchaseOrders = action.payload.purchaseOrders.purchaseorders;
      state.vendor = action.payload.vendor;
      state.loading = false;
    },
    [fetchPurchaseOrders.rejected]: (state) => {
      state.loading = false;
    },
    [updatePurchaseOrder.pending]: (state) => {
      state.loading = true;
    },
    [updatePurchaseOrder.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updatePurchaseOrder.rejected]: (state) => {
      state.loading = false;
    },
    [updateChalanName.pending]: (state) => {
      state.chalanNameLoading = true;
    },
    [updateChalanName.fulfilled]: (state, action) => {
      state.chalanNameLoading = false;
      state.vendor = action.payload.updateData.contact;
    },
    [updateChalanName.rejected]: (state) => {
      state.chalanNameLoading = false;
    },
  },
});

export const getPurchaseOrders = (state) => state.purchaseOrders;

export default purchaseOrdersReducer.reducer;
