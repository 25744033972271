import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "./components/infrastructure/Layout";

import "./css/style.scss";

import "react-datepicker/dist/react-datepicker.css";
import Home from "./pages/Home";
import ComplaintForm from "./pages/ComplaintForm/ComplaintForm";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/complaint-form">
            <Route index element={<Home />} />
            <Route path=":phone" element={<ComplaintForm />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
