import React from "react";

const PrimaryButton = ({ children, className, ...props }) => {
  return (
    <button
      className={`px-20 py-1 bg-blue-800 text-white rounded-xl
      ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
