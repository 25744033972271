import React from "react";
import _ from "lodash";

const FormikInputGroup = ({
  formik,
  label = "",
  required = false,
  name,
  fullWidth,
  ...props
}) => {
  return (
    <div className={fullWidth ? "w-full" : ""}>
      <label className="block uppercase font-thin mb-2" htmlFor={name}>
        {label} {required && <span className="text-black">*</span>}
      </label>
      <input
        type="text"
        value={_.at(formik.values, name)[0]}
        onChange={formik.handleChange}
        name={name}
        onBlur={formik.handleBlur}
        className="border-2 border-[#5b5754] rounded-lg w-[100%]"
        {...props}
      />
      {formik.errors[name] && formik.touched[name] ? (
        <p className="text-xs text-red-500">{formik.errors[name]}</p>
      ) : null}
    </div>
  );
};

export default FormikInputGroup;
