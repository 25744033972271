import { useSelector } from "react-redux";
import { getPurchaseOrders } from "../../app/reducers/PurchaseOrders/purchaseOrderSlice";
import { object, string } from "yup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptionsForPurchaseOrder } from "../../utils/Utils";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikRadioInput from "../../components/formik/FormikRadioInput";
import { ArrowLeft, Users } from "react-feather";
import { Fade } from "react-reveal";
const validationSchema = object({
  clientResponse: string().required("Required"),
});

const STEP = 3;
const action = "Select Response";

const Step = ({ formik, currentStep, setCurrentStep }) => {
  const { purchaseOrders } = useSelector(getPurchaseOrders);

  return (
    <>
      {currentStep + 1 === STEP && (
        <>
          <div className="absolute">
            <ArrowLeft
              type="button"
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
              className="mr-2"
            />
          </div>
          <Fade distance="30px" bottom duration={1000}>
            <div className="flex gap-2 items-center ">
              <Users />
              <h1 className="font-bold ">
                कृपया ग्राहक के द्वारा दिए गए जवाब को दी गई सूची से चुने!{" "}
              </h1>
            </div>
          </Fade>

          <Fade distance="30px" delay={600} bottom duration={1000}>
            <FormikRadioInput
              formik={formik}
              label={"आपके भुगतान के लिए कृपया 4/5 दिन इंतजार करें"}
              name="clientResponse"
              value={"आपके भुगतान के लिए कृपया 4/5 दिन इंतजार करें"}
            />
          </Fade>
          <Fade distance="30px" delay={700} bottom duration={1000}>
            <FormikRadioInput
              formik={formik}
              label={
                "ग्राहक ब्याज देने के लिए तैयार हैं और इंतजार करने को कहते हैं"
              }
              name="clientResponse"
              value={
                "ग्राहक ब्याज देने के लिए तैयार हैं और इंतजार करने को कहते हैं"
              }
            />
          </Fade>
          <Fade distance="30px" delay={800} bottom duration={1000}>
            <FormikRadioInput
              formik={formik}
              label={"ग्राहक के पास फंड की उपलब्धता नहीं है"}
              name="clientResponse"
              value={"ग्ग्राहक के पास फंड की उपलब्धता नहीं है"}
            />
          </Fade>
          <Fade distance="30px" delay={900} bottom duration={1000}>
            <FormikRadioInput
              formik={formik}
              label={"ग्ग्राहक मेरी फोन कॉल का जवाब नहीं दे रहा है"}
              name="clientResponse"
              value={"ग्राहक मेरी फोन कॉल का जवाब नहीं दे रहा है"}
            />
          </Fade>
          <div className="flex justify-end flex-col gap-3 items-center md:flex-row">
            {/* <div className="flex justify-between flex-col gap-3 items-center md:flex-row"> */}
            {/* <PrimaryButton type="button" className="mr-2"
              onClick={async () => {
                const validation = await formik.validateForm();
                console.log(validation)
                if (_.isEmpty(validation)) {
                  formik.submitForm()
                } else {
                  alert("Please choose one the feilds")
                }
              }}

            >
              में संतुष्ट हूँ
            </PrimaryButton> */}
            <PrimaryButton
              type="button"
              onClick={async () => {
                const validation = await formik.validateForm();
                if (_.isEmpty(validation)) {
                  setCurrentStep(currentStep + 1);
                } else {
                  alert("Please choose one the feilds");
                }
              }}
            >
              Next
            </PrimaryButton>
            {/* <PrimaryButton
              type="button"
              onClick={async () => {
                const validation = await formik.validateForm();
                if (_.isEmpty(validation)) {
                  setCurrentStep(currentStep + 1)
                } else {
                  alert("Please choose one the feilds")
                }
              }}
            >
              में संतुष्ट नहीं हूँ
            </PrimaryButton> */}
          </div>
        </>
      )}
    </>
  );
};

export default {
  Component: Step,
  validationSchema: validationSchema,
  action,
};
