import { useDispatch, useSelector } from "react-redux";
import { getPurchaseOrders } from "../../app/reducers/PurchaseOrders/purchaseOrderSlice";
import { object, string } from "yup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptionsForPurchaseOrder } from "../../utils/Utils";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikRadioInput from "../../components/formik/FormikRadioInput";
import { useEffect } from "react";
import { fetchBuyer, getBuyer } from "../../app/reducers/Buyer/buyerSlice";
import { ClipLoader } from "react-spinners";

const validationSchema = {};

const STEP = 0;
const action = "Display Buyer Contact";

const Step = ({ formik, currentStep, setCurrentStep }) => {
  const dispatch = useDispatch();
  const { buyer, loading } = useSelector(getBuyer);
  useEffect(() => {
    if (formik.values.purchaseOrder) {
      console.log(formik.values.purchaseOrder);
      dispatch(fetchBuyer({ purchaseorder_id: formik.values.purchaseOrder }));
    }
  }, [formik.values.purchaseOrder]);

  return (
    <>
      {currentStep + 1 === STEP && (
        <>
          {loading ? (
            <ClipLoader />
          ) : (
            <>
              <div className="flex flex-col items-center text-center ">
                <h1 className="my-3 text-xl ">🏛 {buyer.contact_name} 🏛</h1>
                <a
                  className="my-3 "
                  href={`tel:${buyer?.phone ? buyer.phone : buyer.mobile}`}
                >
                  Please call To,📲 {buyer?.phone ? buyer.phone : buyer.mobile}.
                </a>
                <img
                  className="w-[16rem]"
                  src="/contact.png"
                  alt="contact image"
                />
              </div>

              <div>
                <div className="flex justify-between flex-col gap-3 items-center md:flex-row">
                  <PrimaryButton
                    onClick={() => {
                      setCurrentStep(5);
                    }}
                    type="button"
                    className="mr-2"
                  >
                    में संतुष्ट हूँ
                  </PrimaryButton>
                  <PrimaryButton
                    type="button"
                    onClick={() => {
                      setCurrentStep(2);
                    }}
                  >
                    में संतुष्ट नहीं हूँ
                  </PrimaryButton>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default {
  Component: Step,
  validationSchema: validationSchema,
  action,
};
