import React from "react";
import PrimaryButton from "../components/infrastructure/Buttons/PrimaryButton";
import QueryString from "qs";
import FormikInputGroup from "../components/formik/FormikInputGroup";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      systemId: "",
    },
    validationSchema: object({
      systemId: string().required("System ID is required"),
    }),
    onSubmit: (values) => {
      navigate(`/complaint-form/${values.systemId}`);
    },
  });
  return (
    <div className="flex w-full h-screen justify-center items-center">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <FormikInputGroup
          formik={formik}
          name="systemId"
          label="System ID"
          required
        />
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default Home;
