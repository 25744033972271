import React, { useRef } from "react";
import DatePicker, { Calendar } from "react-multi-date-picker";
import _ from "lodash";

const FormikMultiDateSelect = ({
  formik,
  label = "",
  required = false,
  name,
  ...props
}) => {
  const calenderRef = useRef();
  const handleDateChange = (dates) => {
    // console.log(dates);
    let formattedDates = dates.map((d) => d.format("DD/MM/YYYY"));
    console.log(formattedDates);
    let convertToUnix = dates.map((d) => d.valueOf());
    formik.setFieldValue(name, convertToUnix);
  };

  return (
    <div className="w-full">
      <label className="block uppercase font-thin mb-2" htmlFor={name}>
        {label} {required && <span className="text-black">*</span>}
      </label>
      <div className="border-2 border-[#5b5754] rounded-lg w-[100%]">
        <DatePicker
          ref={calenderRef}
          // minDate={new Date()}
          style={{ width: "100%", height: "43px" }}
          name={name}
          value={formik.values[name]}
          onChange={handleDateChange}
          multiple
          format="DD/MM/YYYY"
          scrollSensitive
          {...props}
        />
      </div>
      {formik?.errors?.[name] && formik?.touched?.[name] ? (
        <p className="text-xs text-red-500">{formik.errors?.[name]}</p>
      ) : null}
    </div>
  );
};

export default FormikMultiDateSelect;
