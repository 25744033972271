import React from "react";
import Select from "react-select";
import _ from "lodash";

const computeValue = (options, val) => {
  const resp = options.find((option) => option.value === val);

  return resp ? resp : null;
};
const FormikSelectGroup = ({
  formik,
  label = "",
  required = false,
  options,
  name,
  refetch = false,
  ignoreDotInName = false,
  ...props
}) => {
  const onChange = props?.onChange
    ? props.onChange
    : (selectedOption) => {
      formik.setFieldValue(name, selectedOption.value);
    };
  console.log(label);
  return (
    <div
      className="w-full"
    >
      <div className="flex items-center gap-2  mb-1">
        <>
          {typeof label === "string" ? (
            <label className="block uppercase font-thin mb-2" htmlFor={name}>
              {label} {required && <span className="text-black">*</span>}
            </label>
          ) : (
            <>{label}</>
          )}
        </>
        {refetch && (
          <p onClick={refetch} className="text-xs underline">
            Refetch
          </p>
        )}
      </div>
      <Select
        name={name}
        value={computeValue(options, _.at(formik.values, name)[0])}
        options={options}
        onBlur={formik.handleBlur}
        onChange={onChange}
        {...props}
        className="border-2 border-[#5b5754] rounded-lg w-[100%]"
      />
      {formik?.errors?.[name] && formik?.touched?.[name] ? (
        <p className="text-xs text-red-500">{formik.errors[name]}</p>
      ) : null}
    </div>
  );
};

export default FormikSelectGroup;
