import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  buyer: {},
  loading: false,
};

export const fetchBuyer = createAsyncThunk(
  "buyer/fetchBuyer",
  async ({ purchaseorder_id }, { rejectWithValue }) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}zoho/purchase-orders/get-buyer`,
        {
          purchaseorder_id,
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }
      console.log(error);
      toast.error("Failed to fetch buyer");
      return rejectWithValue(error);
    }
  }
);

const buyerSlice = createSlice({
  name: "buyer",
  initialState,
  extraReducers: {
    [fetchBuyer.pending]: (state) => {
      state.loading = true;
    },
    [fetchBuyer.fulfilled]: (state, action) => {
      state.loading = false;
      state.buyer = action.payload.data;
    },
    [fetchBuyer.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default buyerSlice.reducer;
export const getBuyer = (state) => state.buyer;
