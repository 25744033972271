import { useSelector } from "react-redux";
import { getPurchaseOrders } from "../../app/reducers/PurchaseOrders/purchaseOrderSlice";
import { object, string } from "yup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptionsForPurchaseOrder } from "../../utils/Utils";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikRadioInput from "../../components/formik/FormikRadioInput";
import { ArrowLeft, CreditCard } from "react-feather";
import { Fade } from "react-reveal";

const validationSchema = object({
  didCall: string().required("Required"),
});

const STEP = 2;
const action = "Did You Call Directly";

const Step = ({ formik, currentStep, setCurrentStep }) => {
  const { purchaseOrders } = useSelector(getPurchaseOrders);
  return (
    <>
      {currentStep + 1 === STEP && (
        <>
          <div className="absolute">
            <ArrowLeft
              color="#003A78"
              type="button"
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
              className="mr-2"
            />
          </div>
          <Fade distance="30px" bottom duration={1000}>
            <div className="flex gap-2 items-center ">
              <CreditCard />
              <h1 className=" font-bold h-8 md:h-auto ">
                {" "}
                क्या आपने सीधे ग्राहक को कॉल किया?अपनी Payment संबंधित शिकायत के
                लिए?
              </h1>
            </div>
          </Fade>
          <Fade distance="30px" delay={800} bottom duration={1000}>
            <FormikRadioInput
              formik={formik}
              label={"हा ,मेने सीधा संपर्क साधा है !"}
              name="didCall"
              value={"yes"}
            />
          </Fade>
          <Fade distance="30px" delay={900} bottom duration={1000}>
            <FormikRadioInput
              formik={formik}
              label={"नहीं, पर में सीधा संपर्क ग्राहक से करना चाहता हूँ। "}
              name="didCall"
              value={"no"}
            />
          </Fade>
          <div>
            <PrimaryButton
              type="button"
              onClick={async () => {
                const validation = await formik.validateForm();
                if (_.isEmpty(validation)) {
                  if (formik.values.didCall === "yes") {
                    setCurrentStep(currentStep + 1);
                  } else {
                    setCurrentStep(-1);
                  }
                } else {
                  alert("Please choose one of the required fields");
                }
              }}
            >
              Next
            </PrimaryButton>
          </div>
        </>
      )}
    </>
  );
};

export default {
  Component: Step,
  validationSchema: validationSchema,
  action,
};
