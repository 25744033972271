const STEP = 5;

const Step = ({ currentStep }) => {
  return (
    <>
      {currentStep + 1 === STEP && (
        <div
          // style={{
          //   backgroundImage: "url('/congrat.gif')",
          //   backgroundSize: "cover",
          // }}
          className="flex flex-col items-center"
        >
          <img
            className="h-[25rem] "
            src="/complaint_success.gif"
            alt="complaint_success"
          />
          {/* <h2 className="text-center text-2xl">Congratulations</h2> */}
          {/* <img src="/congrat.gif" alt="congratulations gif"/> */}
        </div>
      )}
    </>
  );
};

export default {
  Component: Step,
};
