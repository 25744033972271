export const GENDER = [
  { label: "male", value: "male" },
  { label: "female", value: "female" },
];
export const ROLES = [
  { label: "Admin", value: "admin" },
  { label: "Attendee", value: "attendee" },
  { label: "Crew", value: "crew" },
];
export const VOLUME = [
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];
export const FORM = [
  { label: "Liquid", value: "LIQ" },
  { label: "Powder", value: "POW" },
  { label: "SaltFree", value: "SF" },
  { label: "Press Cake", value: "PC" },
  { label: "Granules", value: "GR" },
  { label: "Crystal", value: "CR" },
];

export const TSHIRT_SIZE = [
  { label: "S", value: "S" },
  { label: "M", value: "M" },
  { label: "L", value: "L" },
  { label: "XL", value: "XL" },
  { label: "Xxl", value: "Xxl" },
  { label: "XXxl", value: "XXxl" },
];
export const MUSIC_PREFERENCE = [
  { label: "Rock", value: "Rock" },
  { label: "Jazz", value: "Jazz" },
  { label: "Bollywood party", value: "Bollywood party" },
  { label: "Sufi", value: "Sufi" },
  { label: "Ghazal", value: "Ghazal" },
];
export const FOOD_PREFERENCE = [
  { label: "Veg", value: "Veg" },
  { label: "Non veg", value: "Non veg" },
  { label: "Jain", value: "Jain" },
];

export const CREW_MEMBERS = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
];

export const TITLE = [
  { label: "Mr.", value: "Mr." },
  { label: "Ms.", value: "Ms." },
  { label: "Mrs.", value: "Mrs." },
];

export const AREA_OF_CHECKING = [
  { label: "EXCAVATION", value: "EXCAVATION" },
  { label: "LINE OUT", value: "LINE OUT" },
  { label: "CENTERLINE", value: "CENTERLINE" },
  { label: "FOOTING", value: "FOOTING" },
  { label: "COLUMN STARTER", value: "COLUMN STARTER" },
  { label: "SLAB", value: "SLAB" },
  { label: "SITE VISIT & SITE PHOTOGRAPHS", value: "SITE VISIT PHOTOGRAPH" },
  { label: "OTHERS", value: "OTHERS" },
];

export const TYPE_OF_LEAVES = [
  { label: "Casual Leave", value: "casual" },
  { label: "Medical Leave(MC)", value: "medical" },
  { label: "Emergency Leave", value: "emergency" },
];

export const HALF_DAY_TYPE = [
  { label: "First Half", value: "first_half" },
  { label: "Second Half", value: "second_half" },
];

export const OUTSTANDING_AMOUNT = [
  { label: "Less than 1 lakh", value: "Less than 1 lakh" },
  { label: "1 lakh to 3 lakh", value: "1 lakh to 3 lakh" },
  { label: "3 lakh to 10 lakh", value: "3 lakh to 10 lakh" },
  { label: "10 lakh to 20 lakh", value: "10 lakh to 20 lakh" },
  { label: "More than 20 lakh", value: "More than 20 lakh" },
];
