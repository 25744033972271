import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchPurchaseOrders,
  getPurchaseOrders,
  updatePurchaseOrder,
} from "../../app/reducers/PurchaseOrders/purchaseOrderSlice";
import { ClipLoader } from "react-spinners";
import { useFormik } from "formik";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import {
  formatPurchaseOrderLabel,
  generateOptions,
  generateOptionsForPurchaseOrder,
} from "../../utils/Utils";
import moment from "moment";
import Step1 from "./Step1";
import Step2 from "./Step2";
import StepNegOne from "./StepNegOne";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import AddVendorChallanName from "./AddVendorChallanName";
import { toast } from "react-toastify";
import Step6 from "./Step6";

const ComplaintForm = () => {
  const { phone } = useParams();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [addVendorChallanNameModalOpen, setAddVendorChallanNameModalOpen] =
    useState(false);
  const [validationSchemas, setValidationSchemas] = useState([
    Step1.validationSchema,
    Step2.validationSchema,
    Step3.validationSchema,
    Step4.validationSchema
  ]);
  const { purchaseOrders, vendor, loading } = useSelector(getPurchaseOrders);
  const formik = useFormik({
    initialValues: {
      purchaseOrder: "",
      didCall: "",
      clientResponse: "",
      chalanDates: [],
      chalanName: [],
      outstandingAmount: "",
    },

    validationSchema: validationSchemas[currentStep],
    onSubmit: async (values, { resetForm }) => {
      await dispatch(updatePurchaseOrder(values));
      toast.success("Complaint Recorded");
      setCurrentStep(4);
      resetForm();
    },
  });
  useEffect(() => {
    dispatch(fetchPurchaseOrders({ phone }));
  }, [phone]);
  return (
    <div className="flex justify-center h-screen items-center p-4 relative">
      {loading ? (
        <ClipLoader />
      ) : (
        <div className="flex flex-col w-full items-center  ">
          <AddVendorChallanName
            addVendorChallanNameModalOpen={addVendorChallanNameModalOpen}
            setAddVendorChallanNameModalOpen={setAddVendorChallanNameModalOpen}
          />
          {([4, 5].includes(currentStep) ? "" :
            <div className="my-10 ">
              <label className="flex gap-2 items-center flex-row md:flex-row justify-center">
                <span className="text-xl text-center  text-black my-2 md:text-4xl">COMPLAINT EASE</span>

                {(currentStep == 1 ? <img src="/step2.png" className="  w-16 md:w-32 " alt="labelPic" /> :
                  <img src="/box.png" className="  w-16 md:w-32 " alt="labelPic" />)}
              </label>
              <p className="text-center text grey my-2 text-md md:text-2xl">
                A form for smooth Payment System
              </p>
            </div>

          )}


          <form
            onSubmit={formik.handleSubmit}
            className={([4, 5].includes(currentStep) ? "" : "bg-white p-4 rounded-xl shadow-md flex flex-col gap-4  sm:w-1/2 md:w-3/5 lg:w-1/2 text-xs border border-blue-400 px-7 mb-20 md:mb-0")}
          // className="bg-white p-4 rounded-xl shadow-md flex flex-col gap-4 sm:w-1/2 md:w-3/5 lg:w-1/2 text-xs border border-blue-400 px-7 "
          >
            {([4, 5].includes(currentStep) ? "" : <div> <h1 className="text-[#003A78] m-2 text-center text-lg md:text-xl pt-2">
              Welcome {vendor.contact_name}
            </h1>
              <hr className="my-1" /></div>)}
            <StepNegOne.Component
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              formik={formik}
            />
            <Step1.Component
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              formik={formik}
            />
            <Step2.Component
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              formik={formik}
            />
            <Step3.Component
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              formik={formik}
            />
            <Step4.Component
              currentStep={currentStep}
              formik={formik}
              setCurrentStep={setCurrentStep}
              addVendorChallanNameModalOpen={addVendorChallanNameModalOpen}
              setAddVendorChallanNameModalOpen={
                setAddVendorChallanNameModalOpen
              }
            />
            <Step5.Component
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              formik={formik}
            />
            <Step6.Component
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              formik={formik}
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default ComplaintForm;
