import React, { useState } from "react";
import { nanoid } from "nanoid";

const FormikRadioInput = ({ formik, name, label, value }) => {
  //   console.log(formik?.values?.[name]?.includes(val));
  const id = useState(nanoid());
  return (
    <div className="flex items-center gap-2">
      <input
        type="radio"
        onChange={formik.handleChange}
        name={name}
        value={value}
        checked={formik.values[name] === value}
        id={id}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default FormikRadioInput;
