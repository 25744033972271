import { useDispatch } from "react-redux";
import React from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import { useSelector } from "react-redux";
import {
  getPurchaseOrders,
  updateChalanName,
} from "../../app/reducers/PurchaseOrders/purchaseOrderSlice";
import { FieldArray, FormikProvider, useFormik } from "formik";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import { X } from "react-feather";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

const AddVendorChallanName = ({
  addVendorChallanNameModalOpen,
  setAddVendorChallanNameModalOpen,
}) => {
  const { vendor, chalanNameLoading } = useSelector(getPurchaseOrders);
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      vendorId: vendor.contact_id,
      challanNames: [],
    },
    onSubmit: async (values, { resetForm }) => {
      let chalanNamesUpt = values?.challanNames.filter(d => d == '')
      if(chalanNamesUpt.length > 0 ) {
       return alert('Please fill all chalan names')
      }
      await dispatch(updateChalanName({...values,challanNames: chalanNamesUpt}));
      setAddVendorChallanNameModalOpen(false);
      toast.success("New Chalan Name Added Successfully");
      resetForm();
    },
  });
  return (
    <ModalBasic
      setModalOpen={setAddVendorChallanNameModalOpen}
      modalOpen={addVendorChallanNameModalOpen}
      title="Add Chalan Name"
      onModalClose={() => {
        setTimeout(() => {
          formik.resetForm();
        }, 150);
      }}
    >
      {chalanNameLoading ? (
        <ClipLoader />
      ) : (
        <form
          className="flex flex-col gap-4 p-4"
          onSubmit={formik.handleSubmit}
        >
          <p>Current Chalan Names :</p>
          <div className="grid grid-cols-1 gap-2">
            {vendor.cf_chalan_name?.split(',').filter(d => d != '').map((d,i) => (
              <div>
                <span className="mr-3">{i+1}.</span>{d}
              </div>
            ))}
          </div>
          <FormikProvider value={formik}>
            <FieldArray
              name="challanNames"
              render={(arrayHelpers) => (
                <>
                  <div>
                    {formik.values.challanNames.map((challanName, index) => (
                      <div className="relative" key={index}>
                        <FormikInputGroup
                          name={`challanNames.${index}`}
                          label={`Challan Name ${index + 1}`}
                          key={index}
                          formik={formik}
                        />
                        <X
                          className="absolute top-2 right-2 cursor-pointer"
                          size={10}
                          onClick={(e) => {
                            e.stopPropagation();
                            arrayHelpers.remove(index);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-col sm:flex sm:flex-row items-center justify-center gap-4 sm:gap-2">
                    <PrimaryButton
                      type="button"
                      onClick={() => arrayHelpers.push("")}
                    >
                      Add More
                    </PrimaryButton>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                  </div>
                </>
              )}
            />
          </FormikProvider>
        </form>
      )}
    </ModalBasic>
  );
};

export default AddVendorChallanName;
